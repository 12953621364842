<template>
    <div class="bg-gray-200 h-auto">
        <navigation></navigation>
        <div class="container px-4 py-10 mx-auto max-w-7xl">
            <div class="lg:max-w-lg w-full">
                <h4 class="text-lg font-semibold mb-5">Cetak Laporan Keuangan Harian</h4>
                <div class="flex items-center space-x-2">
                    <div class="w-1/2">
                        <h4 class="text-sm font-medium mr-2 mb-1">Tanggal Mulai:</h4>
                        <input v-model="start_date" class="w-full mr-5 rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-800 text-sm px-3 py-1.5" type="date" @input="getReport">
                    </div>
                    <div class="w-1/2">
                        <h4 class="text-sm font-medium mr-2 mb-1">Tanggal Akhir:</h4>
                        <input v-model="end_date" class="w-full rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-800 text-sm px-3 py-1.5" type="date" @input="getReport">
                    </div>
                </div>
                <div class="flex items-center space-x-2 mt-2">
                    <div class="w-full">
                        <h4 class="text-sm font-medium mr-2 mb-1">Status Order:</h4>
                        <zselect v-model="status_order" :data="['Hold', 'Cancel', 'Manifest', 'Pending']" placeholder="Pilih Status Order" @input="getReport">
                        </zselect>
                    </div>
                </div>
                <div v-if="profileData.type_user === 'Super Admin'" class="flex items-center space-x-2 mt-2">
                    <div class="w-full">
                        <h4 class="text-sm font-medium mr-2 mb-1">Cabang:</h4>
                        <zselect v-model="branch" :data="branchOptions" placeholder="Pilih Cabang" @input="getReport">
                        </zselect>
                    </div>
                </div>
                <button @click="downloadReport" class="text-sm mt-5 bg-blue-600 hover:bg-blue-700 px-4 py-2 text-semibold text-white rounded-md shadow mr-2">Download</button>
                <button @click="start_date = ''; end_date = ''; status_order = ''; data = []" class="text-sm mt-5 bg-gray-600 hover:bg-gray-700 px-4 py-2 text-semibold text-white rounded-md shadow">Reset Filter</button>
            </div>
            <div v-if="data && data.length > 0" class="mt-10">
                <h1 class="text-sm font-semibold mb-3">Preview:</h1>
                <table class="table-auto w-full bg-white shadow-lg rounded-lg overflow-hidden">
                    <thead>
                        <tr>
                            <td class="px-3 py-2 bg-blue-700 text-white font-semibold text-sm">No Order</td>
                            <td class="px-3 py-2 bg-blue-700 text-white font-semibold text-sm">Nama Pengirim</td>
                            <td class="px-3 py-2 bg-blue-700 text-white font-semibold text-sm">Nama Penerima</td>
                            <td class="px-3 py-2 bg-blue-700 text-white font-semibold text-sm">Negara Tujuan Penerima</td>
                            <td class="px-3 py-2 bg-blue-700 text-white font-semibold text-sm">Mitra Ekspedisi</td>
                            <td class="px-3 py-2 bg-blue-700 text-white font-semibold text-sm">No Resi</td>
                            <td class="px-3 py-2 bg-blue-700 text-white font-semibold text-sm">Berat</td>
                            <td class="px-3 py-2 bg-blue-700 text-white font-semibold text-sm">Agen</td>
                            <td class="px-3 py-2 bg-blue-700 text-white font-semibold text-sm">Volume</td>
                            <td class="px-3 py-2 bg-blue-700 text-white font-semibold text-sm">Pickup</td>
                        </tr>
                    </thead>
                    <thead>
                        <tr class="border-b border-gray-200" v-for="(item, index) in data" :key="index">
                            <td class="px-3 py-2 bg-gray-50 text-sm">{{ item.shipment_number }}</td>
                            <td class="px-3 py-2 bg-gray-50 text-sm">{{ item.sender_name }}</td>
                            <td class="px-3 py-2 bg-gray-50 text-sm">{{ item.recipient_name }}</td>
                            <td class="px-3 py-2 bg-gray-50 text-sm">{{ item.recipient_destination }}</td>
                            <td class="px-3 py-2 bg-gray-50 text-sm">{{ item.courier }}</td>
                            <td class="px-3 py-2 bg-gray-50 text-sm">{{ item.awb_no }}</td>
                            <td class="px-3 py-2 bg-gray-50 text-sm">{{ item.weight }}</td>
                            <td class="px-3 py-2 bg-gray-50 text-sm">{{ item.agen }}</td>
                            <td class="px-3 py-2 bg-gray-50 text-sm">{{ item.volume }}</td>
                            <td class="px-3 py-2 bg-gray-50 text-sm">{{ item.pickup }}</td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <zloading v-show="loading"></zloading>
    </div>
</template>

<script>
import navigation from '@/components/navigation';
import zloading from '@/components/zloading';
import zselect from '@/components/zselect';
import axios from 'axios';
import { api_url } from '@/config/api';

export default {
    components: { 
        navigation,
        zloading,
        zselect
    },
    data() {
        return {
            /* Auth Token */
            headers: JSON.parse(window.localStorage.getItem('token-auth-jaskipin')),
            /* Data List */
            data: [],
            /* Pagination */
            /* Filter */
            start_date: '',
            end_date: '',
            status_order: '',
            branch: '',
            /* Toggle Dialog */
            loading: true,
            /* Form Value */
            /* Extra Data */
            branchOptions: []
        }
    },
    computed: {
        profileData() {
            let profile = JSON.parse(window.localStorage.getItem('data-profile-jaskipin'));
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
    },
    created() {
        this.getListBranch();
        setTimeout(() => {
            this.loading = false;
        }, 500);
    },
    methods: {
        async getListBranch() {
            let query_params = {
                pagination: false
            }

            try {
                const response = await axios.get(`${api_url}/api/v1/master/branch/admin`, { params: query_params, headers: this.headers });
                console.log(response);
                this.branchOptions = response.data.map(item => {
                    return {
                        label: item.city_name,
                        value: item.id
                    }
                });
            }catch (error) {
                console.log(error);
            }
        },
        async getReport() {
            this.data = [];
            let query_params = {};

            if(this.start_date !== '' && this.end_date !== '') {
                this.start_date = this.convertDate(this.start_date);
                this.end_date = this.convertDate(this.end_date);

                query_params = {
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status_order: this.status_order
                }

                if(this.profileData.type_user !== 'Super Admin') {
                    query_params.branch = this.profileData.branch._id;
                }else{
                    query_params.branch = this.branch.value;
                }

                try {
                    const response = await axios.get(`${api_url}/api/v1/report/finance/get-report`, { params: query_params, headers: this.headers });
                    console.log(response);

                    this.data = response.data;
                }catch (error) {
                    console.log(error);
                    if(error.message.includes('404') || error.message.includes('500')){
                        // Destroy Data Auth in LocalStorage
                        window.localStorage.removeItem('token-auth-jaskipin');
                        window.localStorage.removeItem('data-profile-jaskipin');
                        // Redirect to Login Page
                        this.$router.push('/');
                    }
                }
            }
        },
        async downloadReport() {
            this.start_date = this.convertDate(this.start_date);
            this.end_date = this.convertDate(this.end_date);

            let query_params = {
                start_date: this.start_date,
                end_date: this.end_date,
                status_order: this.status_order
            }

            if(this.profileData.type_user !== 'Super Admin') {
                query_params.branch = this.profileData.branch._id;
            }else{
                query_params.branch = this.branch.value;
            }

            try {
                const response = await axios.get(`${api_url}/api/v1/report/finance/download-report`, { params: query_params, headers: this.headers });
                console.log(response);

                // var fileDownload = require('js-file-download');
                // fileDownload(response.data, 'laporan-keuangan.xlsx');

                // window.location.href(`${api_url}/uploads/LaporanKeuangan.xlsx`)

                /* Download File via URL */
                setTimeout(async () => {
                    await axios.get(`${api_url}/uploads/LaporanKeuangan.xlsx`, { responseType: 'blob' }).then(res => {
                        const blob = new Blob([res.data], { type: 'application/xlsx' })
                        const link = document.createElement('a')
                        link.href = URL.createObjectURL(blob)
                        link.download = 'LaporanKeuangan.xlsx'
                        link.click()
                        URL.revokeObjectURL(link.href)
                    }).catch(console.error);
                }, 3000);
            }catch (error) {
                console.log(error);
                if(error.message.includes('404') || error.message.includes('500')){
                    // Destroy Data Auth in LocalStorage
                    window.localStorage.removeItem('token-auth-jaskipin');
                    window.localStorage.removeItem('data-profile-jaskipin');
                    // Redirect to Login Page
                    this.$router.push('/');
                }
            }
        },
        /* Helpers */
        formatDate(value) {
            let options = { year: 'numeric', month: 'long', day: 'numeric' };
            let date = new Date(value).toLocaleString('id-ID', options);
            return date;
        },
        convertDate(date) {
            var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        },
    }
}
</script>

<style scoped>

</style>