import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedItem: [],
    selectedColumns: null,
    exclude_branch: []
  },
  mutations: {
    putSelectedItem (state, payload) {
      state.selectedItem = payload;
    },
    putSelectedColumns (state, payload) {
      state.selectedColumns = payload;
    },
    putExcludeBranch (state, payload) {
      state.exclude_branch = payload;
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()],
})
