<template>
    <div class="bg-gray-200 h-auto">
        <navigation></navigation>
        <div class="container px-4 py-10 mx-auto max-w-7xl">
            <div class="flex items-center justify-between mb-6">
                <div class="lg:w-1/2 w-full">
                    <input v-model="search" @input="tableInputSearchHandler($event)" type="text" class="border border-gray-400 px-3 py-2 text-sm rounded-md lg:w-1/2 w-full" placeholder="Pencarian.." />
                </div>
                <!-- <button @click="formDialog = !formDialog; dataSelected = []; emptyFormData()" class="ml-auto bg-green-600 hover:bg-green-700 rounded-md px-3 py-2 text-sm text-white focus:outline-none flex items-center transition duration-300 ease-in">
                    <svg class="w-3.5 h-3.5 mr-2 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform:;-ms-filter:">
                        <path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10c5.514,0,10-4.486,10-10S17.514,2,12,2z M17,13h-4v4h-2v-4H7v-2h4V7h2v4h4V13z"></path>
                    </svg>
                    <h4 class="font-semibold">Tambah</h4>
                </button> -->
            </div>
            <datatable
                :data="data.data"
                :columns="columns"
                :meta="data.meta"
                :PageNavHandler="pageNavHandler"
                :TableInputSearchHandler="tableInputSearchHandler"
            >
                <!-- Custom Field -->
                <template slot="action" slot-scope="props">
                    <div class="flex items-center">
                        <button @click="dataSelected = props.item; detailModalDialog = true" class="bg-gray-200 hover:bg-gray-300 border border-gray-300 hover:border-gray-400 focus:border-gray-400 rounded-md px-3 py-1 text-gray-600 focus:outline-none flex items-center mr-2" style="font-size: 11px">
                            <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                            </svg>
                            <h4 class="font-semibold">Detail Agen</h4>
                        </button>
                        <button @click="dataSelected = props.item; deleteConfirmModal = true" class="bg-red-400 hover:bg-red-500 border border-red-500 hover:border-red-600 focus:border-red-600 rounded-md px-3 py-1 text-white focus:outline-none flex items-center" style="font-size: 11px">
                            <svg class="w-4 h-4 mr-2 fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="transform:;-ms-filter:"><path d="M16,2H8C4.691,2,2,4.691,2,8v13c0,0.553,0.447,1,1,1h13c3.309,0,6-2.691,6-6V8C22,4.691,19.309,2,16,2z M16.706,15.293 l-1.414,1.414L12,13.415l-3.292,3.292l-1.414-1.414l3.292-3.292L7.294,8.709l1.414-1.414L12,10.587l3.292-3.292l1.414,1.414 l-3.292,3.292L16.706,15.293z"></path></svg>
                            <h4 class="font-semibold">Hapus</h4>
                        </button>
                    </div>
                </template>
                <!-- / End Custom Field -->
            </datatable>
        </div>
        <!-- Form Dialog -->
        <div v-show="formDialog" class="fixed inset-0 bg-gray-600 bg-opacity-80 transition-opacity" aria-hidden="true"></div>
        <transition
            enter-active-class="transform transition ease-in-out duration-700 sm:duration-700"
            enter-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="transform transition ease-in-out duration-700 sm:duration-700"
            leave-class="translate-x-0"
            leave-to-class="translate-x-full"
        >
            <addForm v-show="formDialog" @close="formDialog = false" :title="dataSelected.length === 0 ? 'Tambah Agen' : 'Ubah Data Agen'" @submit="handleSubmit">
                <div class="space-y-4">
                    <div>
                        <label id="listbox-label" class="block text-sm leading-5 font-semibold text-gray-800 mb-1">
                            Nama Lengkap
                        </label>
                        <input v-model="full_name" class="w-full rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-800 text-sm px-3 py-2" placeholder="contoh: Ahmad Rizky" type="text">
                    </div>
                    <div>
                        <label id="listbox-label" class="block text-sm leading-5 font-semibold text-gray-800 mb-1">
                            Email
                        </label>
                        <input v-model="email" class="w-full rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-800 text-sm px-3 py-2" placeholder="contoh: ahmad@gmail.com" type="text">
                    </div>
                    <div>
                        <label id="listbox-label" class="block text-sm leading-5 font-semibold text-gray-800 mb-1">
                            No Handphone
                        </label>
                        <input v-model="phone" class="w-full rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-800 text-sm px-3 py-2" placeholder="contoh: 0813-4543-5346" type="text">
                    </div>
                    <div>
                        <label id="listbox-label" class="block text-sm leading-5 font-semibold text-gray-800 mb-1">
                            Alamat Lengkap
                        </label>
                        <input v-model="address" class="w-full rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-800 text-sm px-3 py-2" placeholder="contoh: Jl Siliwangi" type="text">
                    </div>
                    <div>
                        <label id="listbox-label" class="block text-sm leading-5 font-semibold text-gray-800 mb-1">
                            Kecamatan
                        </label>
                        <input v-model="subdistrict" class="w-full rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-800 text-sm px-3 py-2" placeholder="contoh: Gunung Jati" type="text">
                    </div>
                    <div>
                        <label id="listbox-label" class="block text-sm leading-5 font-semibold text-gray-800 mb-1">
                            Kota
                        </label>
                        <input v-model="city" class="w-full rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-800 text-sm px-3 py-2" placeholder="contoh: Cirebon" type="text">
                    </div>
                    <div>
                        <label id="listbox-label" class="block text-sm leading-5 font-semibold text-gray-800 mb-1">
                            Jenis Bangunan
                        </label>
                        <zselect v-model="building_type" :data="['Rumah', 'Ruko', 'Kios', 'Lainnya']" placeholder="Pilih Jenis Bangunan">
                        </zselect>
                    </div>
                    <div>
                        <label id="listbox-label" class="block text-sm leading-5 font-semibold text-gray-800 mb-1">
                            Profesi
                        </label>
                        <zselect v-model="job" :data="['Agen Ekspedisi', 'Karyawan', 'Wirausaha', 'Freelance', 'Lainnya']" placeholder="Pilih Profesi">
                        </zselect>
                    </div>
                    <div v-for="(item, index) in questions" :key="index">
                        <label id="listbox-label" class="block text-sm leading-5 font-semibold text-gray-800 mb-1">
                            {{ item.question }}
                        </label>
                        <div class="relative flex items-center">
                            <input class="w-4 h-4" type="radio" v-model="item.answer" value="Ya" />
                            <label class="ml-1">Ya</label>
                        </div>
                        <div class="relative flex items-center">
                            <input class="w-4 h-4" type="radio" v-model="item.answer" value="Tidak" />
                            <label class="ml-1">Tidak</label>
                        </div>
                    </div>
                    <div>
                        <label id="listbox-label" class="block text-sm leading-5 font-semibold text-gray-800 mb-1">
                            Peta Lokasi
                        </label>
                        <input v-model="map_location" class="w-full rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-800 text-sm px-3 py-2" placeholder="contoh: Isi dengan Koordinat/Longlat" type="text">
                    </div>
                    <div>
                        <label id="listbox-label" class="block text-sm leading-5 font-semibold text-gray-800 mb-1">
                            Photo Lokasi
                        </label>
                        <img v-if="photo_location.imageName && photo_location.imageName !== ''" :src="photo_location.imageURL" width="250" class="mb-2" />
                        <img v-else :src="photo_location" width="250" class="mb-2" />
                        <input id="upload-photo-location" accept="image/*" class="hidden" type="file" @change="uploadFileImageLocation($event)">
                        <label 
                            for="upload-photo-location"
                            type="button"
                            class="cursor-pointer focus:outline-none border py-2 px-4 rounded-lg shadow-sm text-left text-gray-600 bg-white hover:bg-gray-100 font-medium"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" class="inline-flex flex-shrink-0 w-6 h-6 -mt-1 mr-1" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                                <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
                                <circle cx="12" cy="13" r="3" />
                            </svg>						
                            Upload Photo Lokasi
                        </label>
                    </div>
                    <div>
                        <label id="listbox-label" class="block text-sm leading-5 font-semibold text-gray-800 mb-1">
                            Photo KTP
                        </label>
                        <img v-if="photo_ktp.imageName && photo_ktp.imageName !== ''" :src="photo_ktp.imageURL" width="250" class="mb-2" />
                        <img v-else :src="photo_ktp" width="250" class="mb-2" />
                        <input id="upload-photo-ktp" accept="image/*" class="hidden" type="file" @change="uploadFileImageKTP($event)">
                        <label 
                            for="upload-photo-ktp"
                            type="button"
                            class="cursor-pointer focus:outline-none border py-2 px-4 rounded-lg shadow-sm text-left text-gray-600 bg-white hover:bg-gray-100 font-medium"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" class="inline-flex flex-shrink-0 w-6 h-6 -mt-1 mr-1" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                                <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
                                <circle cx="12" cy="13" r="3" />
                            </svg>						
                            Upload Foto KTP
                        </label>
                    </div>
                </div>
            </addForm>
        </transition>
        <!-- Notification -->
        <transition
            enter-active-class="transform transition ease-in-out duration-700 sm:duration-700"
            enter-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="transform transition ease-in-out duration-700 sm:duration-700"
            leave-class="translate-x-0"
            leave-to-class="translate-x-full"
        >
            <notification v-show="successNotification" :message="dataSelected.length === 0 ? 'Berhasil! Agen baru sudah ditambahkan!' : 'Berhasil! Agen sudah diubah!'" @close="successNotification = false"></notification>
        </transition>
        <!-- Delete Confirm Modal -->
        <transition
            enter-active-class="ease-out duration-300"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="opacity-100"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div v-show="deleteConfirmModal" class="fixed inset-0 transition-opacity" aria-hidden="true">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
        </transition>
        <transition
            enter-active-class="ease-out duration-300"
            enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="opacity-100 translate-y-0 sm:scale-100"
            leave-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
            <modal 
                v-show="deleteConfirmModal"
                title="Hapus Data Agen"
                message="Anda yakin ingin menghapus data agen ini?<br/> Data akan dihapus secara permanen. Tindakan ini tidak bisa dibatalkan."
                @close="deleteConfirmModal = false; dataSelected = []"
                @confirm="deleteAgen()"
            ></modal>
        </transition>
        <!-- Detail Data Modal -->
        <transition
            enter-active-class="ease-out duration-300"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="opacity-100"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div v-show="detailModalDialog" class="fixed inset-0 transition-opacity" aria-hidden="true">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
        </transition>
        <transition
            enter-active-class="ease-out duration-300"
            enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="opacity-100 translate-y-0 sm:scale-100"
            leave-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
            <detailModal 
                v-show="detailModalDialog"
                title="Detail Agen"
                @close="detailModalDialog = false; dataSelected = []"
            >
                <dl>
                    <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-xs font-medium text-gray-500">
                        Nama Lengkap
                        </dt>
                        <dd class="mt-1 text-xs text-gray-900 font-semibold sm:mt-0 sm:col-span-2">
                        {{ dataSelected.full_name ? dataSelected.full_name : '-' }}
                        </dd>
                    </div>
                    <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-xs font-medium text-gray-500">
                        Email
                        </dt>
                        <dd class="mt-1 text-xs text-gray-900 font-semibold sm:mt-0 sm:col-span-2">
                        {{ dataSelected.email ? dataSelected.email : '-' }}
                        </dd>
                    </div>
                    <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-xs font-medium text-gray-500">
                        No Handphone
                        </dt>
                        <dd class="mt-1 text-xs text-gray-900 font-semibold sm:mt-0 sm:col-span-2">
                        {{ dataSelected.phone ? dataSelected.phone : '-' }}
                        </dd>
                    </div>
                    <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-xs font-medium text-gray-500">
                        Alamat
                        </dt>
                        <dd class="mt-1 text-xs text-gray-900 font-semibold sm:mt-0 sm:col-span-2">
                        {{ dataSelected.address ? dataSelected.address : '-' }}
                        </dd>
                    </div>
                    <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-xs font-medium text-gray-500">
                        Provinsi
                        </dt>
                        <dd class="mt-1 text-xs text-gray-900 font-semibold sm:mt-0 sm:col-span-2">
                        {{ dataSelected.province ? dataSelected.province : '-' }}
                        </dd>
                    </div>
                    <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-xs font-medium text-gray-500">
                        Kecamatan
                        </dt>
                        <dd class="mt-1 text-xs text-gray-900 font-semibold sm:mt-0 sm:col-span-2">
                        {{ dataSelected.subdistrict ? dataSelected.subdistrict : '-' }}
                        </dd>
                    </div>
                    <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-xs font-medium text-gray-500">
                        Kota
                        </dt>
                        <dd class="mt-1 text-xs text-gray-900 font-semibold sm:mt-0 sm:col-span-2">
                        {{ dataSelected.city ? dataSelected.city : '-' }}
                        </dd>
                    </div>
                    <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-xs font-medium text-gray-500">
                        Jenis Bangunan
                        </dt>
                        <dd class="mt-1 text-xs text-gray-900 font-semibold sm:mt-0 sm:col-span-2">
                        {{ dataSelected.building_type ? dataSelected.building_type : '-' }}
                        </dd>
                    </div>
                    <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-xs font-medium text-gray-500">
                        Profesi
                        </dt>
                        <dd class="mt-1 text-xs text-gray-900 font-semibold sm:mt-0 sm:col-span-2">
                        {{ dataSelected.job ? dataSelected.job : '-' }}
                        </dd>
                    </div>
                    <div v-for="(item, index) in dataSelected.questions" :key="index" class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-xs font-medium text-gray-500">
                        {{ item.question }}
                        </dt>
                        <dd class="mt-1 text-xs text-gray-900 font-semibold sm:mt-0 sm:col-span-2">
                        {{ item.answer }}
                        </dd>
                    </div>
                    <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-xs font-medium text-gray-500">
                        Peta Lokasi
                        </dt>
                        <dd class="mt-1 text-xs text-gray-900 font-semibold sm:mt-0 sm:col-span-2">
                        {{ dataSelected.map_location ? dataSelected.map_location : '-' }}
                        </dd>
                    </div>
                    <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-xs font-medium text-gray-500">
                        Foto Lokasi
                        </dt>
                        <dd class="mt-1 text-xs text-gray-900 font-semibold sm:mt-0 sm:col-span-2">
                            <img :src="dataSelected.photo_location" width="150" class="mb-2" />
                        </dd>
                    </div>
                    <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-xs font-medium text-gray-500">
                        Foto KTP
                        </dt>
                        <dd class="mt-1 text-xs text-gray-900 font-semibold sm:mt-0 sm:col-span-2">
                            <img :src="dataSelected.photo_ktp" width="150" class="mb-2" />
                        </dd>
                    </div>
                    <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-xs font-medium text-gray-500">
                        Instagram
                        </dt>
                        <dd class="mt-1 text-xs text-gray-900 font-semibold sm:mt-0 sm:col-span-2">
                        {{ dataSelected.instagram ? dataSelected.instagram : '-' }}
                        </dd>
                    </div>
                    <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-xs font-medium text-gray-500">
                        Facebook
                        </dt>
                        <dd class="mt-1 text-xs text-gray-900 font-semibold sm:mt-0 sm:col-span-2">
                        {{ dataSelected.facebook ? dataSelected.facebook : '-' }}
                        </dd>
                    </div>
                </dl>
            </detailModal>
        </transition>
        <zloading v-show="loading"></zloading>
    </div>
</template>

<script>
import navigation from '@/components/navigation';
import zselect from '@/components/zselect';
import datatable from '@/components/datatables/advance_table';
import addForm from '@/components/datatables/add_form';
import notification from '@/components/datatables/notification';
import detailModal from '@/components/datatables/detail_modal';
import modal from '@/components/datatables/modal';
import zloading from '@/components/zloading';
import axios from 'axios';
import { api_url } from '@/config/api';

export default {
    components: { navigation, zselect, datatable, addForm, notification, detailModal, modal, zloading },
    data() {
        return {
            /* Auth Token */
            headers: JSON.parse(window.localStorage.getItem('token-auth-jaskipin')),
            /* Data List */
            data: [],
            dataSelected: [],
            columns: [{
                field: 'full_name',
                label: 'Nama Agen'
            },{
                field: 'email',
                label: 'Email'
            },{
                field: 'address',
                label: 'Alamat'
            },{
                field: 'phone',
                label: 'No Handphone'
            },{
                field: 'city',
                label: 'Kota'
            },{
                field: 'action',
                label: 'Aksi'
            }],
            /* Pagination */
            page: 1,
            limit: 10,
            /* Filter */
            search: '',
            /* Toggle Dialog */
            formDialog: false,
            successNotification: false,
            deleteConfirmModal: false,
            detailModalDialog: false,
            loading: true,
            /* Form Value */
            full_name: '',
            address: '',
            subdistrict: '',
            city: '',
            email: '',
            phone: '',
            building_type: '',
            job: '',
            questions: [{
                question: 'Apakah saat ini menjalankan bisnis ekspedisi?',
                answer: ''
            },{
                question: 'Apakah pernah menjadi tenaga kerja di luar negeri?',
                answer: ''
            }],
            map_location: '',
            photo_location: '',
            photo_ktp: '',
            /* Extra Data */
        }
    },
    created() {
        /* Initiate Function */
        this.getListData(this.page, this.limit);
    },
    methods: {
        /*
         * Get Data Method
         */
        async getListData(page, limit) {
            let query_params = {
                page: page,
                limit: limit,
                pagination: true,
                search: this.search
            }

            try {
                const response = await axios.get(`${api_url}/api/v1/users/agen/get`, { params: query_params, headers: this.headers });
                console.log(response);
                this.data = response.data;

                this.loading = false;
            }catch (error) {
                console.log(error);
                if(error.message.includes('404') || error.message.includes('500')){
                    // Destroy Data Auth in LocalStorage
                    window.localStorage.removeItem('token-auth-jaskipin');
                    window.localStorage.removeItem('data-profile-jaskipin');
                    // Redirect to Login Page
                    this.$router.push('/');
                }
            }
        },
        /*
         * Pagination Handler Function
         */
        pageNavHandler (type) {
            switch (type) {
                case 'previous':
                    this.page -= 1;
                    this.getListData(this.page, this.limit);
                break;
                case 'next':
                    this.page += 1;
                    this.getListData(this.page, this.limit);
                break;
            }
        },
        /*
         * Search Function
         */
        tableInputSearchHandler(event) {
            const value = event.target.value; // ambil value dari form input search

            if (value === '') {
                this.search = ''; // Mengkosongkan kembali state querynya
                this.getListData(this.page, this.limit);
            } else {
                this.search = value;
                this.getListData(1, this.limit); // Ketika Filter Search dilakukan tidak bisa jika diset pagenya diatas 1 kalau hasil recordnya gak lebih dari limit/10 record
            }
        },
        /*
         * Create Method
         */
        async createAgen() {
            let data = {
                full_name: this.full_name,
                address: this.address,
                subdistrict: this.subdistrict,
                city: this.city,
                email: this.email,
                phone: this.phone,
                building_type: this.building_type,
                job: this.job,
                questions: this.questions,
                map_location: this.map_location,
                photo_location: this.photo_location,
                photo_ktp: this.photo_ktp,
            }

            try {
                const response = await axios.post(`${api_url}/api/v1/users/agen/create/admin`, data, { headers: this.headers });
                console.log(response.data);

                /* Set Toggle */
                this.formDialog = false;
                this.successNotification = true;

                /* Set Data Empty */
                this.emptyFormData();

                /* Refresh Data */
                this.getListData(this.page, this.limit);
            }catch (error) {
                console.log(error);
            }
        },
        /*
         * Get by Id & Update Method
         */
        async editAgen(id) {
            try {
                const response = await axios.get(`${api_url}/api/v1/users/agen/get/${id}`, { headers: this.headers });
                console.log(response);

                /* Set Data Selected */
                this.dataSelected = response.data.data[0];

                /* Set Toggle */
                this.formDialog = true;

                /* Set Data Form */
                this.full_name = this.dataSelected.full_name;
                this.address = this.dataSelected.address;
                this.subdistrict = this.dataSelected.subdistrict;
                this.city = this.dataSelected.city;
                this.email = this.dataSelected.email;
                this.phone = this.dataSelected.phone;
                this.building_type = this.dataSelected.building_type;
                this.job = this.dataSelected.job;
                this.questions = this.dataSelected.questions;
                this.map_location = this.dataSelected.map_location;
                this.photo_location = this.dataSelected.photo_location;
                this.photo_ktp = this.dataSelected.photo_ktp;
            }catch (error) {
                console.log(error);
            }
        },
        async updateAgen() {
            let data = {
                full_name: this.full_name,
                address: this.address,
                subdistrict: this.subdistrict,
                city: this.city,
                email: this.email,
                phone: this.phone,
                building_type: this.building_type,
                job: this.job,
                questions: this.questions,
                map_location: this.map_location,
                photo_location: this.photo_location,
                photo_ktp: this.photo_ktp,
            }

            try {
                const response = await axios.put(`${api_url}/api/v1/users/agen/update/${this.dataSelected.id}`, data, { headers: this.headers });
                console.log(response.data);

                /* Set Toggle */
                this.formDialog = false;
                this.successNotification = true;

                /* Set Data Empty */
                this.emptyFormData();

                /* Refresh Data */
                this.getListData(this.page, this.limit);
            }catch (error) {
                console.log(error);
            }
        },
        /*
         * Delete Method
         */
        async deleteAgen() {
            try {
                const response = await axios.delete(`${api_url}/api/v1/users/agen/delete/${this.dataSelected.id}`, { headers: this.headers });
                console.log(response.data);

                /* Refresh Data */
                this.getListData(this.page, this.limit);
                this.dataSelected = [];

                /* Set Toggle */
                this.deleteConfirmModal = false;
            }catch (error) {
                console.log(error);
            }
        },
        /* Helpers */
        formatPrice(number) {
            var rupiah = '';		
            var angkarev = number.toString().split('').reverse().join('');
            for(var i = 0; i < angkarev.length; i++) if(i%3 == 0) rupiah += angkarev.substr(i,3)+'.';
            return rupiah.split('',rupiah.length-1).reverse().join('');
        },
        emptyFormData() {
            this.full_name = '';
            this.address = '';
            this.subdistrict = '';
            this.city = '';
            this.email = '';
            this.phone = '';
            this.building_type = '';
            this.job = '';
            this.questions = [{
                question: 'Apakah saat ini menjalankan bisnis ekspedisi?',
                answer: ''
            },{
                question: 'Apakah pernah menjadi tenaga kerja di luar negeri?',
                answer: ''
            }];
            this.map_location = '';
            this.photo_location = '';
            this.photo_ktp = '';
        },
        handleSubmit() {
            if(this.dataSelected.length === 0){
                this.createAgen();
            }else{
                this.updateAgen();
            }
        },
        uploadFileImageLocation(event) {
            // Set Ulang File Image
            this.photo_location = {
                imageFile: '',
                imageName: '',
                imageURL: ''
            }

            // Reference to the DOM input element
            var input = event.target;

            this.photo_location.imageFile = input.files[0];
            this.photo_location.imageName = input.files[0].name;

            // Ensure that you have a file before attempting to read it
            if (input.files && input.files[0]) {

                // create a new FileReader to read this image and convert to base64 format
                var reader = new FileReader();

                // Define a callback function to run, when FileReader finishes its job
                reader.onload = (e) => {
                    // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                    // Read image as base64 and set to imageData
                    this.photo_location.imageURL = e.target.result;
                }

                // Start the reader job - read file as a data url (base64 format)
                reader.readAsDataURL(input.files[0]);
            }
        },
        uploadFileImageKTP(event) {
            // Set Ulang File Image
            this.photo_ktp = {
                imageFile: '',
                imageName: '',
                imageURL: ''
            }

            // Reference to the DOM input element
            var input = event.target;

            this.photo_ktp.imageFile = input.files[0];
            this.photo_ktp.imageName = input.files[0].name;

            // Ensure that you have a file before attempting to read it
            if (input.files && input.files[0]) {

                // create a new FileReader to read this image and convert to base64 format
                var reader = new FileReader();

                // Define a callback function to run, when FileReader finishes its job
                reader.onload = (e) => {
                    // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                    // Read image as base64 and set to imageData
                    this.photo_ktp.imageURL = e.target.result;
                }

                // Start the reader job - read file as a data url (base64 format)
                reader.readAsDataURL(input.files[0]);
            }
        }
    }
}
</script>

<style scoped>

</style>