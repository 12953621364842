import { render, staticRenderFns } from "./surat-jalan.vue?vue&type=template&id=150ed0a9&scoped=true&"
import script from "./surat-jalan.vue?vue&type=script&lang=js&"
export * from "./surat-jalan.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "150ed0a9",
  null
  
)

export default component.exports